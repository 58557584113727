export const messages: TableModule = {
    name: "messages",
    route: "spa.telegram.messages",
    defaultOrder: {
        column: "id",
        direction: "asc"
    },
    columns: [
        {
            name: "id",
            sortable: true
        },
    ],
    filters: [
        {
            name: "bot",
            column: "messages.bot_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.telegram.bots.index')
            ).then(response => response.data?.map(
                bot => ({
                    value: bot.id,
                    label: bot.name
                }))
            ),
            reduced: true,
            multiple: true
        },
    ],
    tools: []
}