export const orders: TableModule = {
    name: "orders",
    route: "spa.telegram.orders",
    columns: [
        {
            name: "name",
            pageLink: true,
            sortable: true,
            mobile: true
        },
    ],
    tools: [
        {
            isCreateAction: true,
        }
    ]
}