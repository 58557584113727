import {contacts} from './tableModules/contacts'
import {loginProviders} from './tableModules/loginProviders'
import {organisations} from "./tableModules/organisations"
import {users} from './tableModules/users'
import {bots} from "~/utils/tableModules/bots"
import {mailTemplates} from "~/utils/tableModules/mailTemplates"
import {messages} from "~/utils/tableModules/messages"
import {orders} from "~/utils/tableModules/orders"

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders,
    mailTemplates,
    bots,
    orders,
    messages
}
