export const bots: TableModule = {
    name: "bots",
    route: "spa.telegram.bots",
    columns: [
        {
            name: "name",
            pageLink: true,
            sortable: true,
            mobile: true
        },
        {
            name: "telegram_chat_id",
            mobile: true
        },
        {
            name: "active",
            mobile: true
        },
        {
            name: "mt4_active",
            mobile: true
        },
        {
            name: "mt4_status",
            mobile: true
        },
    ],
    tools: [
        {
            isCreateAction: true,
        }
    ]
}